import React, { Component } from 'react';
import './EditTab.css';
import '../../../Global.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



class EditTab extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
    }


  render() {
      let isActiveTab = this.props.activeEditTab;
    return (
        <div className="edit-tab background-color-lighter flex-justify-content-center mr-2">
              
            <div className={`edit-tab-title  text-center px-2 flex-direction-row flex-align-self-center ${isActiveTab ? 'color-blue' : 'color-dark-grey'}`}>
                <div onClick={this.props.onEditTabClick} >
                    {this.props.name} {((!this.props.savedActiveWindow && isActiveTab) && '*')} {(this.props.id !== undefined && '(' + this.props.id + ')')}
                </div>
                <div className="ml-1" onClick={this.props.onCloseButtonClick}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                </div>
            </div>
        </div>
    );
  }
}

export default EditTab;