import React, { Component } from 'react';
import './EditWindow.css';
import '../../Global.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { handlePost } from '../../ajax/AjaxRequests';
import Script from '.././script/Script';
import EditFolder from '.././create/EditFolder';


class EditWindow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scriptValue : '',
            scriptTitleValue : '',
            createFolder : false,
            newFolderTitle : '',
            forceUpdate : false,
            parentFolder : undefined,
            editScript : false,
            newScript : false,
            editFolder : false,
            editFolderID : 0,
            editScriptID : 0,
            activeEditTab : undefined,
        };

        this.createFolderID = 13;
        this.updateFolderID = 14;
        this.addScriptID = 15;
        this.editScriptID = 16;
    }

    componentDidMount() {
        window.addEventListener("keydown", this.onKeyPressed);
        

        let activeEditTab = this.props.activeEditTab;
        this.setState({
            activeEditTab : activeEditTab,
        }, () => {
            this.handleEditTab(activeEditTab);
        });
    }

    componentWillUnmount(){
        window.removeEventListener('keydown', this.onKeyPressed, false);
    }

    onKeyPressed = (e) => {   
        if(e.key==='s' && e.ctrlKey) {
            e.preventDefault();
            this.onSaveCommand();
        }
    }

    handleEditTab(activeEditTab) {
        let type = activeEditTab.type;
        let state = activeEditTab.state;
        let isNew = state === 'new';
        let isEdit = state === 'edit';
        let isDelete = state === 'delete';
        let parentFolder = activeEditTab.parentFolder;

        if(type === 'folder') {

            if(isNew) {
                this.onCreateFolderClick(parentFolder);
            } else if(isEdit) {
                this.onEditFolderClick(activeEditTab.folder);
            } else if(isDelete) {
                this.onDeleteFolderClick(activeEditTab.folder);
            }


        } else if(type === 'script') {
            if(isNew) {
                this.onCreateScriptClick(parentFolder)
            }  else if(isEdit) {
                this.onEditScriptClick(activeEditTab.script);
            } else if(isDelete) {
                this.onDeleteScriptClick(activeEditTab.script);
            }
            
        }
    }

    onSaveCommand() {
        console.log('pressed save!');

        let activeEditTab = this.state.activeEditTab;
        if(this.state.createFolder && !this.state.editFolder) {
            let title = this.state.newFolderTitle;

            let data =[
                {
                    key : 'folder_title',
                    value : title,
                },
                {
                    key: 'folder_parent',
                    value : this.state.parentFolder.id,
                }
            ];

            handlePost(this.createFolderID, data).then((response) => {
                let status = response['status'];

                if(status) {
                    this.setState({
                        createFolder : false,
                        newFolderTitle : '',
                        forceUpdate : true,//!this.state.forceUpdate,
                    }, () => {
                        this.props.onCloseButtonClick(activeEditTab);
                        this.setState({
                            forceUpdate : false,
                        });
                        this.props.updateNavigation();
                    });
    
                }
            });
            

        } else if(this.state.editFolder) {
            let title = this.state.newFolderTitle;

            let data =[
                {
                    key : 'folder_title',
                    value : title,
                },
                {
                    key: 'folder_id',
                    value : this.state.editFolderID,
                }
            ];

            handlePost(this.updateFolderID, data).then((response) => {
                let status = response['status'];

                if(status) {
                    this.setState({
                        editFolder : false,
                        newFolderTitle : '',
                        forceUpdate : true,
                        editFolderID : 0,
                    }, () => {
                        this.setState({
                            forceUpdate : false,
                        });
                    });
                }
            });
        
        } else if(this.state.editScript) {
            let scriptTitle = this.state.scriptTitleValue;
            let scriptValue = this.state.scriptValue;
            let requestID = this.addScriptID;
            

            let data =[
                {
                    key : 'script_name',
                    value : scriptTitle,
                },
                {
                    key: 'data',
                    value : scriptValue,
                },
                {
                    key : 'folder_id',
                    value : (activeEditTab.script === undefined ? activeEditTab.parentFolder.id : activeEditTab.script.folder_id),
                }
            ];


            if(!this.state.newScript) {
                data.push({
                    key : 'script_id',
                    value : this.state.editScriptID
                });

                requestID = this.editScriptID;
            } 

            handlePost(requestID, data).then((response) => {
                let status = response['status'];


                if(status) {
                    let lastUpdateID = this.state.editScriptID;
                    if(this.state.newScript) {
                        lastUpdateID = parseInt(response['last_id']);                    
                    }

                    this.setState({
                        editScriptID : lastUpdateID,
                        forceUpdate : true,
                        editScript : true,
                        newScript : false,
                    }, () => {
                        this.setState({
                            forceUpdate : false,
                        });
                    });
                }
            });
        } 

        this.props.updateNavigation();
    }

    setScriptValue(data) {
        this.setState({
            scriptValue : data
        }, () => {
            this.props.onEditWindowChanges();
        });
    }

    onNewFolderTitleChange(event) {
        let title = event.target.value;
        this.setState({
            newFolderTitle : title,
        }, () => {
            this.props.onEditWindowChanges();
            this.props.onEditTabTitleChanged(title);
        });
    }

    onCreateFolderClick(parentFolder) {
        this.setState({
            parentFolder : parentFolder,
            createFolder : true,
        });
    }

    onScriptTitleChange(event) {
        let title = event.target.value;
        this.setState({
            scriptTitleValue : title,
        }, () => {
            this.props.onEditWindowChanges();
            this.props.onEditTabTitleChanged(title);
        });
    }

    onCreateScriptClick(parentFolder) {
        this.setState({
            editScript : true,
            newScript : true,
            parentFolder : parentFolder,
            scriptValue : '',
            editScriptID : 0,
            scriptTitleValue : '',
        });
    }

    onEditScriptClick(script) {
        this.setState({
            editScript : true,
            newScript : false,
            scriptTitleValue : script.name,
            scriptValue : script.data,
            editScriptID : script.id,
        })
    }

    onDeleteScriptClick(script) {

    }

    onEditFolderClick(folder) {
        this.setState({
            newFolderTitle : folder.name,
            editFolderID : folder.id,
            editFolder : true,
        });
    }

    onDeleteFolderClick(folder) {

    }

    


  render() {
    let editFolder = this.state.createFolder || this.state.editFolder;

    return (
        <div className="h-100">
            <div className="px-3 w-100 h">
                {editFolder && 
                    <EditFolder value={this.state.newFolderTitle} onChange={this.onNewFolderTitleChange.bind(this)} />
                }
                {this.state.editScript &&
                    <Script width={'100%'} height={'100%'} language={'php'} newScript={this.state.newScript} onScriptTitleChange={this.onScriptTitleChange.bind(this)} titleValue={this.state.scriptTitleValue}  scriptValue={this.state.scriptValue} setScriptValue={this.setScriptValue.bind(this)}/>
                }
            </div>
        </div>
    );
  }
}

export default EditWindow;