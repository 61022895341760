import React, { Component, Fragment } from 'react';
import './App.css';
import './Global.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row } from 'react-bootstrap';
import { TITLE } from './constants/Constants';
import {
  Routes,
  Route,
} from "react-router-dom";
import Dashboard from './cms/dashboard/Dashboard';
import Login from './components/login/Login';
import Footer from './components/footer/Footer';
class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
          user : undefined,
          pages : [],
        };
    }

    componentDidMount() {
        document.title = TITLE;
        this.createPages();
    }

    createPages() {
      let user = this.state.user;
      let pages = [
        {
            title : 'CMS',
            icon : '',
            link : '/cms'
        }
      ];

      this.setState({
        pages : pages
      });
    }

    updateUser(user) {
      this.setState({
        user : user,
      }, () => {
        this.createPages();
      });
    }



  render() {
    return (
      <div className='background-color color-white parent m-auto p-0'>
        {
          (this.state.pages.length > 0 &&
            <div>
              <Row className='h-100'>
              </Row>
              {
                (this.state.user !== undefined  ?
                  <Dashboard user={this.state.user} updateUser={this.updateUser.bind(this)} />
                  :
                  <Login user={this.state.user} updateUser={this.updateUser.bind(this)} />
                  )
              }
              <Footer className=''/>
          </div>
            )
        }
      </div>
    );
  }
}

export default App;