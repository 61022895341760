import React, { Component } from 'react';
import './EditFolder.css';
import '../../Global.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Form } from 'react-bootstrap';

class EditFolder extends Component {

    constructor(props) {
        super(props);
        this.titleField = undefined;
        this.state = {

        };
    }

    componentDidMount() {
        this.titleField.focus();
    }


  render() {
    return (
        <div className="">
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>Title (CTRL+S to save.)</Form.Label>
                    <Form.Control ref={c => (this.titleField = c)} className="folder-title" type="text" placeholder="Title here" onChange={this.props.onChange} value={this.props.value} />
                </Form.Group>
                </Form>
        </div>
    );
  }
}

export default EditFolder;