import React, { Component } from 'react';
import './Welcome.css';
import '../../Global.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Config from '../../config/Config';
import { Container } from 'react-bootstrap';

class Welcome extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }


  render() {
    return (
        <Row className="">
            <Row className="f-5">
                Welcome to the CMS version {Config.version} .
            </Row>
            <Row className="mt-5 ">
                You can develop your PHP pages inside this CMS.
                To start developing create a script/folder or edit via the navigation on the left.
            </Row>
            <Row className="mt-5">
                CMS developed by {Config.creator}.
            </Row>
        </Row>
    );
  }
}

export default Welcome;