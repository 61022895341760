import React, { Component } from 'react';
import './Login.css';
import '../../Global.css';
import Controller from '../../controller/Controller';
import { LOGIN_MODEL } from '../../controller/models/Models';
import { Row, Col, Container } from 'react-bootstrap';

class Login extends Component {

    constructor(props) {
        super(props);
        this.controller = new Controller();
        this.state = {
            pincode : '',
            pincodeValid : true,
            pincodeLength : 5,
            errorMessage : 'Invalid pincode',
        };
    }

    componentDidMount() {

    }


    handlePincodeChange(ref) {
        let value = ref.target.value;
        let currentPincode = this.state.pincode;

        if(currentPincode.length == this.state.pincodeLength)
            return;

        if(isNaN(value))
            return;

        this.setState({
            pincode : value,
        }, () => {
            
            if(value.length == this.state.pincodeLength) {

                this.controller.getModelData(LOGIN_MODEL, 'form', [{
                    key : 'pincode',
                    value : parseInt(this.state.pincode)
                }]).then((response) => {
                    let status = response.status;

                    if(status) {
                        this.setState({
                            pincodeValid : true,
                            pincode : '',
                            errorMessage : undefined,
                        }, (() => {
                            this.props.updateUser(response.user);
                        }))
                        
                    } else {
                        let error = response.error;
                        this.setState({
                            pincodeValid : false,
                            pincode : '',
                            errorMessage : error
                        });
                    }
                });
                
            }
        });
    }


  render() {

    let value = this.state.pincode;
    return (
        <div className="">
            <Container className='m-auto items-center'>
                <div className='f-5 text-bold text-center'>
                    Enter pincode to login
                </div>
                {
                    (!this.state.pincodeValid && 
                        <div className='color-red f-3 text-bold text-center'>
                            {
                                this.state.errorMessage
                            }
                        </div>
                        )
                }
                <div className='flex-justify-content-center color-dark-grey'>
                    <input type='password' onChange={this.handlePincodeChange.bind(this)} value={this.state.pincode} />
                </div>
                
            </Container>
        </div>
    );
  }
}

export default Login;