export const PROJECT_MODEL = {
    modelID : 1,
    overview: 25,
    view : 26,
    form : undefined,
}

export const ROLES_MODEL = {
    modelID : 2,
    overview: 27,
    view : undefined,
    form : undefined,
}

export const LOGIN_MODEL = {
    modelID : 3,
    overview: undefined,
    view : undefined,
    form : 29,
}

export const SUBSCRIPTION_MODEL = {
    modelID : 4,
    overview: 30,
    view : undefined,
    form : undefined,
}